import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/pages/login/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
  },
]

const router = new VueRouter({
  routes,
})

router.afterEach((to, form, next) => {
  if (process.env.NODE_ENV === 'production') {
    setTimeout(() => {
      document.getElementById('baidu_tj') &&
        document.getElementById('baidu_tj').remove()
      var _hmt = _hmt || []
      ;(function () {
        var hm = document.createElement('script')
        hm.src = 'https://hm.baidu.com/hm.js?3e83d83770de30dae531add3fea84937'
        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(hm, s)
      })()
    }, 0)
  }
})

export default router
