<template>
  <div class="codeSwitch">
    <!-- tab选项卡 -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="全球赢App扫码" name="appCode"></el-tab-pane>
      <el-tab-pane label="微信扫码" name="wxCode"></el-tab-pane>
    </el-tabs>

    <!-- 二维码展示区域 -->
    <div class="code_body">
      <component :is="activeName"></component>
    </div>
  </div>
</template>

<script>
import wxCode from '@/components/code/wxCode'
import appCode from '@/components/code/appCode'
export default {
  name: 'codeSwitch',
  components: {
    wxCode,
    appCode,
  },
  data() {
    return {
      activeName: 'appCode',
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.codeSwitch {
  width: 100%;
  height: 100%;
  padding: 30px 20px 0;
  overflow: hidden;
  position: relative;

  ::v-deep .el-tabs {
    // margin-top: 25px;
    width: 80%;

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__nav-wrap::after {
      content: none;
    }

    .el-tabs__item {
      font-size: 15px;
      padding: 0 15px;
    }
  }
}
</style>
