<template>
  <div class="appCode">
    <!-- 二维码展现 -->
    <div class="appCode_body" v-if="!success">
      <div class="code_body">
        <img src="~@/assets/codeTip.png" class="tips_img" />
        <div class="code">
          <img :src="`data:image/jpeg;base64,${imgBase}`" />

          <!-- 失效展示 -->
          <div class="failure" v-if="isFailure">
            <i class="el-icon-refresh-right refresh_icon"></i>
            <el-button @click="refresh" size="mini" type="primary"
              >点击刷新</el-button
            >
          </div>
        </div>
      </div>
      <div class="tips_text">打开 全球赢 App</div>
      <div class="tips_text">在「首页」顶部打开扫一扫</div>
    </div>
    <!-- 扫码成功后等待确认 -->
    <div class="success_body" v-else>
      <div class="success_ico">
        <img src="~@/assets/success.png" alt="" />
      </div>
      <div class="success_text">扫码成功</div>
      <div class="success_tip">请在手机上点击「确认登录」按钮</div>
    </div>
  </div>
</template>

<script>
import loginApi from '@/services/login/login'

export default {
  name: 'appCode',
  data() {
    return {
      timer: '',
      imgBase: '',
      success: false,
      isFailure: false,
    }
  },
  mounted() {
    this.getAppCode()
  },
  methods: {
    // 获取验证码&启动轮询
    async getAppCode() {
      let res = await loginApi.appCode({
        width: 180,
        height: 180,
      })
      this.imgBase = res.data
      this.isFailure = false
      this.getAppCodeStatus()
      this.timer = window.setInterval(() => {
        window.setTimeout(this.getAppCodeStatus, 0)
      }, 1500)
    },
    // 轮询二维码状态
    async getAppCodeStatus() {
      let res = await loginApi.appCodeStatus({
        base64Str: this.imgBase,
      })
      let { status, token } = res.data
      switch (status) {
        case 1:
          this.success = true
          break
        case 2:
          this.isFailure = true
          window.clearInterval(this.timer)
          break
        default:
          break
      }

      if (token) {
        window.location.href = `${process.env.VUE_APP_DOMAIN}?token=${token}`
      }
    },
    refresh() {
      window.clearInterval(this.timer)
      this.getAppCode()
    },
  },
  destroyed() {
    window.clearInterval(this.timer)
  },
}
</script>

<style lang="less" scoped>
.appCode {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .appCode_body {
    margin-top: 40px;

    .code_body {
      display: flex;

      .tips_img {
        width: 109px;
        height: 175px;
        margin-left: 20px;
      }

      .code {
        position: relative;

        .failure {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: hsla(0, 0%, 100%, 0.8);
          color: #409eff;

          .refresh_icon {
            font-size: 35px;
            cursor: pointer;
          }

          .el-button {
            margin-top: 10px;
            padding: 3px 8px;
          }

          p {
            font-weight: 600;
          }
        }
      }
    }

    .tips_text {
      margin-top: 10px;
      text-align: center;
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 17px;
    }
  }

  .success_body {
    margin-top: 60px;
    text-align: center;

    .success_ico {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    }

    .success_text {
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
    }

    .success_tip {
      margin-top: 20px;
    }
  }
}
</style>
