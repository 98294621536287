<template>
  <div class="agreement">
    <span>登录代表您同意【</span>
    <el-link
      type="primary"
      href="https://tools.iglobalwin.com/privacy_policy"
      target="_blank"
      >隐私政策</el-link
    >
    <span>】及【</span>
    <el-link
      type="primary"
      href="https://tools.iglobalwin.com/user_services_agreement"
      target="_blank"
      >服务协议</el-link
    >
    <span>】</span>
  </div>
</template>

<script>
export default {
  name: 'agreement',
}
</script>

<style lang="less" scoped>
.agreement {
  width: 100%;
  position: absolute;
  bottom: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
</style>
