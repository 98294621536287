<template>
  <div
    id="login"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-container>
      <el-main>
        <!-- <div class="log">
          <img src="../../../static/image/log.png"
               alt="">
        </div> -->
        <div class="login_content">
          <div class="log">
            <img src="../../assets/content.png" alt="" />
          </div>
          <el-footer>
            <div class="concat_us">
              <span
                >官网：<a href="https://www.topsky.com">www.topsky.com</a></span
              >
              <span>
                全球赢：<a href="https://www.iglobalwin.com"
                  >www.iglobalwin.com</a
                ></span
              >
              <span>全国服务热线：400-620-8365</span>
              <span>咨询邮箱：market@topsky.com</span>
            </div>
            <div class="addr">
              Copyright © 2019 -
              {{ new Date().getFullYear() }} 上海天擎天拓信息技术股份有限公司
              版权所有 | 沪ICP备15001856号-5
            </div>
          </el-footer>
        </div>
        <!-- <div class="tips_outer">
          <div class="tip_header">AI大数据营销系统</div>
          <div class="tip_one">专注企业海外推广，一站式外贸整合营销SaaS系统</div>
          <div class="tip_two">网站管理 | 销售线索 | 营销分析 | 社交媒体服务 | 数据管理</div>
        </div> -->
        <div class="code_content">
          <div class="loginWindow">
            <div class="qyCode" @click="iconClick">
              <!-- <span class="login_tips"><img src="../../../static/image/safe.png" />{{isActive?'验证码登录在这里':'扫码登录更安全'}}</span> -->
              <i
                :class="[
                  'iconfont',
                  'active',
                  isActive ? 'icondiannao' : 'iconerweima1',
                ]"
              ></i>
            </div>
            <div class="loginTips" v-if="login_content == 'passWord'">
              <div class="loginTitle" v-html="loginTitle"></div>
            </div>
            <component :is="login_content"></component>

            <!-- 协议 -->
            <agreement />

            <!-- app下载提示 -->
            <downloadTip />
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import passWord from '@/components/passWord'
// import wxCode from "@/components/wxCode"
import codeSwitch from '@/components/codeSwitch.vue'
import loginApi from '@/services/login/login'

import downloadTip from '@/components/downloadTip'
import agreement from '@/components/agreement'

export default {
  name: 'login',
  components: { passWord, codeSwitch, downloadTip, agreement },
  data() {
    return {
      login_content: 'codeSwitch',
      loginTitle: '',
      day: 5,
      loading: false,
      isActive: true,
      code: '',
    }
  },
  watch: {
    code: {
      handler: function (val) {
        if (!val) {
          this.getCode()
        }
      },
      immediate: true,
    },
  },
  methods: {
    iconClick() {
      this.isActive = !this.isActive
      if (this.isActive) {
        this.login_content = 'codeSwitch'
        this.loginTitle = ''
      } else {
        this.login_content = 'passWord'
        this.loginTitle = '验证码登录'
      }
    },
    async getCode() {
      let url = window.location.href
      this.code = window.location.search
        ? window.location.search.split('&')[0].split('=')[1]
        : ''
      let name = window.location.search
        ? window.location.search.split('&')[0].split('=')[0]
        : ''
      name = name.replace('?', '')
      try {
        if (this.code) {
          this.loading = true

          if (name.trim() === 'customerId') {
            await loginApi.loginBuriedPoint(url)
            this.loading = false
            return
          }
          var res = await loginApi.wxlogin({ code: this.code })
          localStorage.setItem('Token', res.data.token)
          setTimeout(() => {
            window.location.href = `${process.env.VUE_APP_DOMAIN}?token=${res.data.token}`
          }, 1000)
        } else {
          this.loading = false
        }
      } catch (e) {
        this.loading = false
        //window.location.href=window.location.origin
      }
    },
  },

  mounted() {},
}
</script>
<style lang="less">
#login {
  .el-container {
    height: 100%;
    .el-main {
      position: relative;
      display: flex;

      .login_content {
        flex: 7;
        height: 100%;

        .log {
          width: 100%;
          height: 90%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin-top: 15%;
            width: 600px;
            height: 650px;
          }
        }
      }
    }
    .el-footer {
      text-align: center;
      .concat_us {
        color: #d7d7d7;
        margin-bottom: 6px;
        a {
          color: inherit;
          cursor: pointer;
        }
        a:hover {
          color: #5aa0ff;
        }
      }
      .addr {
        color: #d7d7d7;
      }
    }
  }
  .tips_outer {
    width: 653px;
    color: #fff;
    position: relative;
    top: 200px;
    left: 155px;
    .tip_header {
      font-size: 53px;
      margin-bottom: 28px;
    }
    .tip_one {
      font-size: 28px;
      margin-bottom: 16px;
    }
    .tip_two {
      font-size: 20px;
    }
  }
  .topic {
    color: #eade1d;
    font-size: 19px;
    margin-top: 57px;
    margin-left: 197px;
  }
  // background: url("https://img.iglobalwin.com/login-bg.jpg?x-oss-process=image/format,webp") no-repeat;
  // background: url("https://img.iglobalwin.com/image/login-bg.jpg") no-repeat;
  background: url('../../assets/bj-.png') no-repeat;

  background-position: center top;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  .code_content {
    flex: 3;
    height: 100%;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginWindow {
      position: relative;
      width: 340px;
      height: 440px;
      // right: 155px;
      // top: 200px;
      background: #fff;
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      .qyCode {
        position: absolute;
        right: 0;
        margin: 10px;
        z-index: 1;
        cursor: pointer;

        .login_tips {
          color: #f9be01;
          font-size: 14px;
          vertical-align: top;
          display: inline-block;
          border: 1px solid #f9be01;
          padding: 5px 20px;
          font-weight: bold;
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
            vertical-align: bottom;
            margin-right: 5px;
          }
          &::after {
            content: '';
          }
        }

        i {
          font-size: 55px;
          color: #409eff;
        }
      }
      .el-main,
      .el-container {
        height: 100%;
      }
      .loginTips {
        padding-left: 60px;
        padding-top: 70px;
      }
      .loginTitle {
        color: #3da1ff;
        font-weight: bold;
        line-height: 50px;
        font-size: 24px;
        height: 50px;
      }
      .el-form {
        padding: 50px 60px 0px 60px;
        .submit {
          width: 100%;
          font-size: 20px;
        }
        .el-form-item {
          // margin-bottom: 40px;
          label {
            color: #666666;
            font-size: 18px;
            font-weight: bold;
            line-height: 35px;
          }
          .el-form-item__content {
            line-height: 0;
            .el-input__inner {
              padding: 0;
              border-top: none;
              border-left: none;
              border-right: none;
              border-radius: 0;
              color: #666666;
              height: 30px;
            }
            .chose_pwa {
              a {
                margin-left: 136px;
                color: #d5d5d5;
                font-weight: normal;
                &:hover {
                  color: #3da1ff;
                }
              }
              label {
                @extend a;
                margin: 0;
              }
            }
          }
        }
        .telText {
          margin-bottom: 40px;
          .el-input .el-input__clear {
            color: #d5d5d5;
          }
          .el-input .el-input__clear:hover {
            color: #909399;
          }
        }
        .passWordText {
          .el-input__suffix-inner {
            .iconfont {
              display: inline-block;
              margin-top: 16px;
              font-size: 14px;
              margin-right: 5px;
            }
          }
        }
        .el-button--primary {
          margin-top: 17px;
          background-color: #2a87fd;
          box-shadow: 0px 2px 4px 0px rgba(28, 117, 255, 0.2);
          border-radius: 4px;
          &:hover {
            background-color: #1a96ff;
          }
        }
      }
      #code {
        // height: 360px;
        // margin-top: 78px;
        iframe {
          height: 330px;
        }
      }
    }
  }
  @media only screen and (max-width: 1890px) {
    .tips_outer {
      top: 180px;
      left: 105px;
      .tip_header {
        font-size: 48px;
      }
      .tip_one {
        font-size: 26px;
      }
      .tip_two {
        font-size: 18px;
      }
    }
    // .loginWindow {
    //   top: 200px;
    //   right: 105px;
    // }

    .log {
      img {
        width: 550px !important;
        height: 600px !important;
      }
    }
    #code {
      // margin-top: -40px;
    }
  }
}
</style>
