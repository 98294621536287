<template>
  <div class="qy_code">
    <div id="code"></div>
  </div>
</template>
<script>
export default {
  name: 'qy_code',
  data() {
    return {}
  },
  mounted() {
    let appid = null
    if (process.env.NODE_ENV == 'production') {
      appid = 'wx821a6ae52a4e8e57'
    } else if (process.env.NODE_ENV == 'preProduction') {
      appid = 'wxd12976956b53b84a'
    } else {
      appid = 'wx28f54ff910cfac87' //zou  wx28f54ff910cfac87   //lin wx821a6ae52a4e8e57
    }
    var obj = new WxLogin({
      self_redirect: false,
      id: 'code',
      appid: appid,
      scope: 'snsapi_login',
      redirect_uri: window.location.origin, //'https://i.iglobalwin.com',  https://i-test.iglobalwin.com
      state: '3955020000000092725',
      style: '',
      href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7DQogIHdpZHRoOiAxNjBweDsNCn0NCi5pbXBvd2VyQm94IC5pY29uMzhfbXNnIHsNCiAgYmFja2dyb3VuZC1zaXplOiAzNnB4IDM2cHg7DQp9DQouaW1wb3dlckJveCAuc3RhdHVzX3N1Y2MgLnN0YXR1c190eHQgaDQsDQouaW1wb3dlckJveCAuc3RhdHVzX2ZhaWwgLnN0YXR1c190eHQgaDQgew0KICBmb250LXNpemU6IDE2cHg7DQogIHRleHQtYWxpZ246IGxlZnQ7DQp9DQouaW1wb3dlckJveCAuc3RhdHVzIHsNCiAgbWFyZ2luLXRvcDogMDsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXNfc3VjYywNCi5pbXBvd2VyQm94IC5zdGF0dXNfZmFpbCB7DQogIHRleHQtYWxpZ246IGNlbnRlcjsNCn0NCg0KLmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCg0KLm9sZC10ZW1wbGF0ZSB7DQogIG1hcmdpbi10b3A6IDM1cHg7DQp9DQoNCi5pbXBvd2VyQm94IC5zdGF0dXNfYnJvd3NlciBwew0KICBmb250LXNpemU6IDE3cHg7DQogIGZvbnQtZmFtaWx5OiBQaW5nRmFuZ1NDLVNlbWlib2xkLCBQaW5nRmFuZyBTQzsNCiAgZm9udC13ZWlnaHQ6IDYwMDsNCiAgY29sb3I6ICMzMzMzMzM7DQp9DQo=',
    })
  },
}
</script>
<style lang="less">
#code {
  height: 312px;
  text-align: center;
  iframe {
    height: 312px;
  }
}
</style>
