import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import buriedPoint from '@/common/buriedPoint.js'
import commonFn from '@/common/commonFn'
import skyRequest from 'skytech-request'

import '@/less/base.less'
import '@/less/app.less'
import '@/iconfont/iconfont.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false

if (process.env == 'production') {
  buriedPoint()
}

Vue.use(ElementUI)

window.ElementUI = ElementUI

skyRequest.install(function (status, message) {
  commonFn.tipPop.error(message)
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
