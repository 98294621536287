import { render, staticRenderFns } from "./passWord.vue?vue&type=template&id=5f14d631&scoped=true"
import script from "./passWord.vue?vue&type=script&lang=js"
export * from "./passWord.vue?vue&type=script&lang=js"
import style0 from "./passWord.vue?vue&type=style&index=0&id=5f14d631&prod&lang=less&scoped=true"
import style1 from "./passWord.vue?vue&type=style&index=1&id=5f14d631&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f14d631",
  null
  
)

export default component.exports