import {
  $http,
  Get,
  Post,
  Put,
  Patch,
  Delete,
  RequestUse
} from 'skytech-request'

const addToken = (config) => {
  config.headers = {
    ...config.headers,
    Token: `${localStorage.getItem("Token")}`
  }
  return config;
}
RequestUse(addToken);

export {
  Get,
  Post,
  Put,
  Patch,
  Delete
}
