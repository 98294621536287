import api from '../../apiConfig/api_config'
import { Post, Get } from '../base/baseAjax'
let loginApi = (param) => {
  return Post({
    url: api.client_login,
    bodyParam: param,
  })
}
let getCode = (mobile) => {
  return Get({
    url: api.client_code,
    PathParam: mobile,
  })
}
let wxlogin = (code) => {
  return Get({
    url: api.qycode,
    PathParam: code,
  })
}

let loginBuriedPoint = (pagePath) => {
  return Post({
    url: api.loginBuriedPoint,
    bodyParam: {
      pagePath,
    },
  })
}

let appCode = (params) => {
  return Get({
    url: api.appCode,
    UrlParam: params,
  })
}

let appCodeStatus = (params) => {
  return Get({
    url: api.appCodeStatus,
    UrlParam: params,
  })
}

export default {
  loginApi: loginApi,
  getCode: getCode,
  wxlogin,
  loginBuriedPoint,
  appCode,
  appCodeStatus,
}
