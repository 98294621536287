<template>
  <div class="pass_word">
    <el-form :model="loginForm" ref="loginForm" :rules="loginRules">
      <el-row>
        <el-col :span="7" class="mobileCode_body"
          ><el-form-item prop="mobileCode">
            <el-select v-model="loginForm.mobileCode" placeholder="区号">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.code"
                :value="item.code"
              >
                <span>{{ `${item.name}(${item.code})` }}</span>
              </el-option>
            </el-select></el-form-item
          ></el-col
        >
        <el-col :span="16"
          ><el-form-item prop="mobile" class="telText">
            <el-input
              v-model="loginForm.mobile"
              placeholder="手机号码"
              auto-complete="off"
              clearable
              maxlength="11"
              minlength="11"
            >
            </el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-form-item label="" class="code" prop="code">
        <el-input
          v-model.trim="loginForm.code"
          placeholder="请输入验证码"
          @keyup.enter.native="submitForm"
        >
          <template slot="append">
            <el-button
              type="text"
              :disabled="!this.loginForm.mobile || disabled"
              @click="getCode"
              >{{ codeText }}</el-button
            >
          </template>
        </el-input>
      </el-form-item>
      <el-button
        type="primary"
        class="submit"
        @click="submitForm"
        :loading="loading"
        ref="btn"
        >{{ loadingText }}</el-button
      >
    </el-form>
  </div>
</template>
<script>
import loginApi from '@/services/login/login'

let options = [
  { name: '中国', code: '+86', flag: '🇨🇳' },
  { name: '日本', code: '+81', flag: '🇯🇵' },
  { name: '韩国', code: '+82', flag: '🇰🇷' },
  { name: '印度', code: '+91', flag: '🇮🇳' },
  { name: '印度尼西亚', code: '+62', flag: '🇮🇩' },
  { name: '马来西亚', code: '+60', flag: '🇲🇾' },
  { name: '新加坡', code: '+65', flag: '🇸🇬' },
  { name: '泰国', code: '+66', flag: '🇹🇭' },
  { name: '菲律宾', code: '+63', flag: '🇵🇭' },
  { name: '越南', code: '+84', flag: '🇻🇳' },
  { name: '英国', code: '+44', flag: '🇬🇧' },
  { name: '德国', code: '+49', flag: '🇩🇪' },
  { name: '法国', code: '+33', flag: '🇫🇷' },
  { name: '意大利', code: '+39', flag: '🇮🇹' },
  { name: '西班牙', code: '+34', flag: '🇪🇸' },
  { name: '俄罗斯', code: '+7', flag: '🇷🇺' },
  { name: '荷兰', code: '+31', flag: '🇳🇱' },
  { name: '瑞士', code: '+41', flag: '🇨🇭' },
  { name: '瑞典', code: '+46', flag: '🇸🇪' },
  { name: '丹麦', code: '+45', flag: '🇩🇰' },
  { name: '美国', code: '+1', flag: '🇺🇸' },
  { name: '加拿大', code: '+1', flag: '🇨🇦' },
  { name: '墨西哥', code: '+52', flag: '🇲🇽' },
  { name: '古巴', code: '+53', flag: '🇨🇺' },
  { name: '巴西', code: '+55', flag: '🇧🇷' },
  { name: '阿根廷', code: '+54', flag: '🇦🇷' },
  { name: '智利', code: '+56', flag: '🇨🇱' },
  { name: '秘鲁', code: '+51', flag: '🇵🇪' },
  { name: '哥伦比亚', code: '+57', flag: '🇨🇴' },
  { name: '委内瑞拉', code: '+58', flag: '🇻🇪' },
  { name: '南非', code: '+27', flag: '🇿🇦' },
  { name: '埃及', code: '+20', flag: '🇪🇬' },
  { name: '尼日利亚', code: '+234', flag: '🇳🇬' },
  { name: '肯尼亚', code: '+254', flag: '🇰🇪' },
  { name: '坦桑尼亚', code: '+255', flag: '🇹🇿' },
  { name: '摩洛哥', code: '+212', flag: '🇲🇦' },
  { name: '澳大利亚', code: '+61', flag: '🇦🇺' },
  { name: '新西兰', code: '+64', flag: '🇳🇿' },
  { name: '巴布亚新几内亚', code: '+675', flag: '🇵🇬' },
  { name: '斐济', code: '+679', flag: '🇫🇯' },
]

export default {
  name: 'pass_word',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    var validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        mobile: '',
        code: '',
        mobileCode: '+86',
      },
      options,
      codeText: '获取验证码',
      disabled: false,
      counter: 60,
      token: '',
      checked: false,
      loading: false,
      loadingText: '登 录',
      loginRules: {
        mobile: [{ validator: validateName, trigger: 'blur' }],
        code: [{ validator: validatePass, trigger: 'blur' }],
      },
    }
  },
  watch: {
    'loginForm.mobile': function (val) {
      this.$refs.loginForm.validateField('mobile', (validMessage) => {
        if (validMessage !== '') {
          this.disabled = true
        } else {
          this.disabled = false
        }
      })
    },
  },
  methods: {
    async submitForm() {
      let _this = this
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.loadingText = '登录中....'
          try {
            let result = await loginApi.loginApi({
              mobile: this.loginForm.mobile,
              code: this.loginForm.code,
              mobileCode: this.loginForm.mobileCode,
            })

            if (result.status == 200) {
              localStorage.setItem('Token', result.data.token)
              window.location.href = `${process.env.VUE_APP_DOMAIN}?token=${result.data.token}`
            } else {
              this.$notify.warning({
                message: result.data.desc,
              })
              this.loadingText = '重新登录'
              this.loading = false
            }
          } catch (err) {
            this.loading = false
            this.loadingText = '重新登录'
          }
        }
      })
    },
    async getCode() {
      try {
        let result = await loginApi.getCode({
          mobile: this.loginForm.mobile,
          mobileCode: this.loginForm.mobileCode,
        })
        if (result.status == 200) {
          this.$notify.success({
            message: `验证码已发送至${this.loginForm.mobile.substring(
              0,
              3
            )}"****"${this.loginForm.mobile.substring(7, 11)},请勿重复发送`,
          })
          this.timer()
        }
      } catch (e) {}
    },
    timer() {
      let timer = setInterval(() => {
        this.counter--
        if (this.counter > 0) {
          this.codeText = this.counter + 'S重新获取'
          this.disabled = true
        } else {
          this.codeText = '重新获取'
          this.disabled = false
          clearInterval(timer)
          this.counter = 60
        }
      }, 1000)
    },
  },
}
</script>
<style lang="less" scoped>
.pass_word {
  .mobileCode_body {
    margin-right: 5px;
    ::v-deep .el-form-item {
      .el-form-item__content {
        .el-select {
          .el-input {
            .el-input__suffix {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.code {
  .el-input-group__append {
    background-color: transparent !important;
    border-top: none;
    border-right: none;
    button {
      color: #5aa0ff;
    }
  }
}
</style>
