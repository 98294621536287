let host = process.env.VUE_APP_API_DOMAIN

const apis = {
  qycode: `${host}/iglobalwin-sso-api/auth/login/scan/web/{code}`, //企业微信code
  client_login: `${host}/iglobalwin-sso-api/auth/login/web`, //登录
  client_code: `${host}/iglobalwin-sso-api/auth/mobile/web/{mobileCode}/{mobile}`, //获取验证码
  loginBuriedPoint: `${host}/web/buried_point/login`, //登录页埋点-新增
  appCode: `${host}/iglobalwin-sso-api/auth/qrcode`, // 扫码登录二维码
  appCodeStatus: `${host}/iglobalwin-sso-api/auth/qrcode/status`, // 二维码状态
}

export default apis
