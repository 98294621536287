function buriedPoint() {
  var hm = document.createElement('script')
  hm.src = 'https://img.iglobalwin.com/track/track.js'
  hm.setAttribute('siteid', '1311408964436492288')
  hm.setAttribute('url', 'https://api.skymonitor.skytech.cn/')
  hm.setAttribute('id', 'trackjs')
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
}

export default buriedPoint
